<template lang="pug">
  v-container.flex-column
    v-row
      v-col(
        cols="12"
        lg="12"
        md="12"
      )
        v-layout(
          flex
          justify-space-between
        )
          TopProductsButton(
            :topProducts="25"
          )
          CartButton
          TopProductsButton(
            :topProducts="50"
          )
    v-row
      v-col
        v-row
          v-col.storage-box(
            cols="12"
            md="6"
            v-for="(drawerGroup, index) in drawerGroups"
            :key="index"
          )
            .storage-background
              router-link.drawer(
                v-for="(drawer, drawerIndex) in drawerGroup"
                :to="{ name: 'drawer', params: { drawerId: drawer.slug } }"
                :key="drawer.slug"
              )
                .drawer-horizontal-fragment
                  .drawer-vertical-fragment.left
                  .drawer-vertical-fragment.right
                .drawer-fragment
                  h4.drawer-name {{ drawer.name }}

</template>

<script>
import parts from '@/data/parts.json'
import TopProductsButton from '@/components/TopProductsButton'
import CartButton from '@/components/CartButton'

export default {
  name: 'Drawers',
  components: {
    TopProductsButton,
    CartButton
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    drawers () {
      return this.data.drawers
    },
    drawerGroups () {
      const groupedDataArr = []
      for (let i = 0; i < this.drawers.length; i += 5) {
        const group = this.drawers.slice(i, i + 5)
        let count = 5 - group.length
        while (count > 0) {
          group.push(false)
          count -= 1
        }
        groupedDataArr.push(group)
      }
      if (groupedDataArr.length > 1 && groupedDataArr.length % 2) {
        const emptyDrawerBox = [...Array(5)].map(() => false)
        groupedDataArr.push(emptyDrawerBox)
      }
      return groupedDataArr
    }
  },
  data: () => ({
    data: parts
  })
}
</script>

<style lang="scss">
  .layout {
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
  .storage-background {
    background: #C4C4C4;
    width: 100%;
    height: 100%;
    padding: 15px;
  }

  .storage-box {
    background: #494949;
    padding: 19px;
  }

  .drawer {
    margin-bottom: 10px;
    display: block;

    text-decoration: none;
  }

  .drawer-fragment {
    background: #F4CC52;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 50px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    text-decoration: none;
    &:hover, &:focus {
      text-decoration: none;
    }
    &.default {
      pointer-events: none;
      cursor: default;
    }
    padding-left: 45px;
    padding-right: 45px;
  }

  .drawer-name {
    // height: 30.32px;
    background: radial-gradient(50% 420.08% at 50% 52.45%, #DBDBDB 0%, #B3B3B3 100%);
    border-radius: 3px;
    // margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    max-width: 100%;
  }

  .drawer-horizontal-fragment {
    display: flex;
    width: calc(100% + 8px);
    height: 7.32px;
    background:#E1B222;
    justify-content: space-between;
    position: relative;
    left: -4px;
  }

  .drawer-vertical-fragment {
    width: 23.98px;
    height: 27.5px;
    background: #E1B222;
    margin-top: -2px;
    &.left{
      margin-left: 19px;

    }
    &.right{
      margin-right: 19px;
    }
  }
</style>
